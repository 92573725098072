import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Items from "./components/Items"; 

class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      items: [
        {
          id: 1,
          title: 'hot-internet1',
          img: 'hot-internet.jpg',
          desc: 'soon will be',
          category: 'internet',
          tarif: '50'
        },
        {
          id: 2,
          title: 'hot-internet2',
          img: 'hot-internet.jpg',
          desc: 'soon will be',
          category: 'internet',
          tarif: '50'
        },
        {
          id: 3,
          title: 'hot-internet3',
          img: 'hot-internet.jpg',
          desc: 'soon will be',
          category: 'internet',
          tarif: '50'
        },
        {
          id: 4,
          title: 'hot-internet4',
          img: 'hot-internet.jpg',
          desc: 'soon will be',
          category: 'internet',
          tarif: '50'
        },
        {
          id: 5,
          title: 'hot-internet5',
          img: 'hot-internet.jpg',
          desc: 'soon will be',
          category: 'internet',
          tarif: '50'
        }
      ],
      filter: 'all', // Инициализируем фильтр как 'all' для отображения всех элементов
    };
  }

  // Метод для обновления состояния фильтра
  setFilter = (category) => {
    this.setState({ filter: category });
  }

  // Метод для фильтрации элементов
  getFilteredItems = () => {
    if (this.state.filter === 'all') {
      return this.state.items;
    }
    return this.state.items.filter(item => item.category === this.state.filter);
  }

  render() {
    const filteredItems = this.getFilteredItems();
    return (
      <div className="wrapper">
        <Header setFilter={this.setFilter} />
        <Items items={filteredItems}/>
        <Footer />
      </div>
    );
  }
}

export default App;