import React, { Component } from 'react';

export default class Item extends Component {
  state = {
    isFeedbackFormVisible: false,
  };

  toggleFeedbackForm = () => {
    this.setState(prevState => ({
      isFeedbackFormVisible: !prevState.isFeedbackFormVisible
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Обработка данных формы
    // ...
  }

  render() {
    const { img, title, desc, tarif } = this.props.item;
    const { isFeedbackFormVisible } = this.state;

    return (
      <div className='item'>
        <img src={`${process.env.PUBLIC_URL}/img/${img}`} alt={title} />
        <h2>{title}</h2>
        <p>{desc}</p>
        <b>{tarif}₪</b>
        <div className='add-to-cart' onClick={this.toggleFeedbackForm}>+</div>
        
        {isFeedbackFormVisible && (
          <form onSubmit={this.handleSubmit}>
            <input type="text" name="name" placeholder="Ваше имя" required />
            <input type="tel" name="phone" placeholder="Ваш телефон" required />
            <button type="submit">Отправить</button>
          </form>
        )}
      </div>
    );
  }
}
